@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.heartImageWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;

}

.listingLike {
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  top: calc(50% + 30px);
  right: 7px;
  transform: translateY(-50%);
  cursor: pointer;
}

.heartImageWrapper .heartIcon {
  height: 75px;
  width: 75px;
  background: url('../../assets/favourite.png');
  background-position: left;
  cursor: pointer;
  position: absolute;
}

.heartImageWrapper .listingLike {
  background: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heartImageWrapper .heartIcon.liked {
  animation: animate 0.7s steps(28) forwards;
}

@keyframes animate {

  to {
    background-position: right;
  }

}

.disabled {
  cursor: not-allowed !important;
}

.listingTypeBadge {
  display: flex;
  padding: 3px 11px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-bottom: 1rem;

  border-radius: 4px;
  border: 1px solid #BF4C20;
  background: #F9EDE5;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #BF4C20;
}